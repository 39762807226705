import axios from 'axios';
import React, { useEffect, useState,useRef, useLayoutEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import {Formik,Form,Field,ErrorMessage} from "formik"
import * as yup from 'yup'
import Footer from './Footer';
import { jsPDF } from 'jspdf';
import ReCAPTCHA from 'react-google-recaptcha';
import group from '../img/Group.png'
import { Helmet } from 'react-helmet';
import LangagueChange from './LangagueChange';
import { data } from './data';
function Car() {
  const { id } = useParams();
  const [car, setCar] = useState(data.find((e) => e.id === parseInt(id)));

   const [cars, setCars] = useState(
     data.filter((e) => e.category === car.category && e.id !== parseInt(id))
   );
   const [I2,setI2]=useState(0);
   const [option,setOption]=useState(false);
   const [captcha,setCaptcha]=useState(false);
   const handleWhatsAppPDFSend = () => {
    const doc = new jsPDF();
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width;
    const pageHeight = pageSize.height;
    const contentWidth = pageWidth - 40;
    const startX = (pageWidth - contentWidth) / 2;
    let currentY = 20;

    // Add double border
    doc.setLineWidth(2);
    doc.rect(5, 5, pageWidth - 10, pageHeight - 10, 'S');

    // Add title
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 255); // Blue color
    doc.text('Transport Request Details', startX+30, currentY, { align: 'center' });
    currentY += 10;

    // Add image
    doc.addImage(group, 'PNG', startX, currentY, 20, 15);
    currentY += 50;

    // Add content
    doc.setFontSize(12);
    const contentList = [
        `Client: ${obgsheet.nameclient}`,
        `Journey: ${obgsheet.pick} to ${obgsheet.drop}`,
        `Pickup Date and Time: ${obgsheet.daystart}, ${obgsheet.hourstart} to ${obgsheet.dayend}, ${obgsheet.hourend}`,
        `Contact Details: Email - ${obgsheet.email}, Phone Number - ${phoneregion + obgsheet.pn}`,
        `Flight: ${obgsheet.fn}`,
        `Vehicle: ${obgsheet.name}`,
        `Total Cost: ${obgsheet.prix} euro`,
    ];

    doc.setTextColor(0); // Black color

    contentList.forEach(item => {
        const lines = doc.splitTextToSize(item, contentWidth);
        doc.text(lines, startX, currentY);
        currentY += (lines.length * 10) + 5;
    });

    // Add date
    const currentDate = new Date().toLocaleDateString();
    const dateX = pageWidth - 10 - doc.getStringUnitWidth(currentDate) * doc.internal.getFontSize();
    doc.text(currentDate, dateX, 15);

    // Add button
    const buttonWidth = 100;
    const buttonHeight = 15;
    const buttonX = (pageWidth - buttonWidth) / 2;
    const buttonY = pageHeight - 40;

    doc.setFillColor(0, 0, 255); // Blue background
    doc.setDrawColor(255); // White border
    doc.roundedRect(buttonX, buttonY, buttonWidth, buttonHeight, 5, 5, 'FD');

    doc.setTextColor(255); // White text
    doc.setFont('helvetica', 'bold'); // Setting font and font weight
    doc.text('Click here to Confirm On WhatsApp', buttonX+8, buttonY +8);
    const whatsappLink = encodeURI(`https://wa.me/+212666703720`);
    doc.setPage(1);
    doc.link(buttonX, buttonY, buttonWidth, buttonHeight, { url: whatsappLink });

    doc.save('transport_request.pdf');
};


   useEffect(()=>{
    axios.get(`http://82.180.136.212/cars/${id}`).then((res)=>{
        setCar(res.data);
        return res.data.category;
    }).then((category)=>{
      axios.get('https://82.180.136.212/cars').then((res)=>{
       
        
      return res.data
      
    }).then((res)=>{setCars(res.filter((e)=>{return (parseInt(e.id)!==parseInt(id) && e.category===category)}))})
     
    })
   
  setIsLoading(true);
   },[option])
   
   var maxDate = new Date(2025,4,30);
   var minDate = new Date(2024,4,1);
   const [obgsheet,setObgsheet]=useState({})
   const form=useRef(null);
   const total=useRef(null);
   const form1=useRef(null);
   const form2=useRef(null);
   const booking=useRef(null);
   const countinues=useRef(null);
   const [isLoading, setIsLoading] = useState(true);
   const [phoneregion, setPhoneregion] = useState('');
   const hoursInDay = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];
const airportsInMorocco = [
    {
      name: "Mohammed V International Airport",
      code: "CMN",
      city: "Casablanca",
    },
    {
      name: "Marrakech Menara Airport",
      code: "RAK",
      city: "Marrakech",
    },
    {
      name: "Agadir Al Massira Airport",
      code: "AGA",
      city: "Agadir",
    },
    {
      name: "Fes–Saïss Airport",
      code: "FEZ",
      city: "Fez",
    },
    {
      name: "Rabat–Salé Airport",
      code: "RBA",
      city: "Rabat",
    },
    {
      name: "Tangier Ibn Battuta Airport",
      code: "TNG",
      city: "Tangier",
    },
    {
      name: "Nador International Airport",
      code: "NDR",
      city: "Nador",
    },
    {
      name: "Oujda–Angads Airport",
      code: "OUD",
      city: "Oujda",
    },
  ];
 
  const handleCaptchaChange = () => {
    // The value parameter contains the user's response token.
    // You can use it for further verification or processing.
   return setCaptcha(true);
  };
  function handleform12(data){
     form1.current.style.top='100%';
     form1.current.style.zIndex='2'
     total.current.style.zIndex='1'
     setObject1(data)
    }
    function handleform22(){
        form1.current.style.top='220%';
       total.current.style.zIndex='-1'
        
       }
     function handleform13(data){
        form2.current.style.top='100%';
        form1.current.style.top='220%';
        form2.current.style.zIndex='2'
     total.current.style.zIndex='1'

        setObject2(data)
      console.log(object1)
     setObgsheet(Object.assign({}, object1, data,{name:car.title},{daystart:dayStart,dayend:dayEnd,prix:calculateDaysBetweenDates(dayStart,dayEnd)*car.prix}))
     console.log(obgsheet)
    }
    function handleform123(){
      form1.current.style.top='100%';
      form2.current.style.top='220%';
      total.current.style.zIndex='1'

     }
       function handleform23(){
        form2.current.style.top='220%';
        total.current.style.zIndex='-1'

       }
  const [dayStart,setDayStart]=useState();
    const [dayEnd,setDayEnd]=useState();
  function calculateDaysBetweenDates(daystart, dayend) {
    // Split date strings into year, month, and day components
    if(dayEnd&&dayStart){ 
     const [startYear, startMonth, startDay] = daystart.split('-');
    const [endYear, endMonth, endDay] = dayend.split('-');
    
    // Create Date objects from components (months are 0-based in JavaScript Date)
    const startDate = new Date(startYear, startMonth - 1, startDay);
    const endDate = new Date(endYear, endMonth - 1, endDay);
    
    // Calculate the difference in milliseconds
    const dateDifference = endDate - startDate;
     console.log(dateDifference)
    // Convert milliseconds to days
    const numDays = dateDifference / (1000 * 60 * 60 * 24);
    console.log(numDays);
    return Math.floor(numDays)+1; // Round down to get the whole number of days
     } }
     const validationBooking=yup.object().shape({
        pick:yup.string().required('select option'),
        drop:yup.string().required('select option'),
        daystart:yup.date(),
        dayend:yup.date(),
        hourstart:yup.string().required('select option'),
        hourend:yup.string().required('select option')
      })
      
    const [object1,setObject1]=useState({});
    const [object2,setObject2]=useState({});
  const navigate=useNavigate();
    const handleSubmit = async e => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "post",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify([
            [`${obgsheet.dayend}`,`${obgsheet.daystart}`,`${phoneregion+obgsheet.pn}`,`${obgsheet.fn}`,`${obgsheet.nameclient}`,`${obgsheet.name}`,`${obgsheet.prix}`,`${obgsheet.drop}`,`${obgsheet.pick}`,`${obgsheet.email}`,`${obgsheet.hourstart}`,`${obgsheet.hourend}`]
            
            ])
        };
        fetch(`https://v1.nocodeapi.com/${process.env.REACT_APP_SHEET_CODE}`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
            handleWhatsAppPDFSend();
            handleform23();
          
      }
      const validationContinues=yup.object().shape({
        nameclient:yup.string().required('set your name'),
        email:yup.string().required('set email'),
        pn:yup.string().min(8).required('set your phone'),
        fn:yup.string().min(4).required('set your flight number')
      })
      
      
       useEffect(()=>{
        setTimeout(() => {
          setIsLoading(false);
        }, 1000); // Simulate a 2-second loading time
        if(isLoading===false){
         const phoneInputField = document.querySelector("#phone");
        const phone1 = document.querySelector('#phone1');
        var phoneInput = window.intlTelInput(phoneInputField, {
  utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        });
        
        // Function to update the input field value with the selected country code
        function updatePhoneNumberWithCountryCode() {
          const selectedCountryData = phoneInput.getSelectedCountryData();
          const selectedCountryCode = `+${selectedCountryData.dialCode}`;
        
          // Always display the selected country code
          phone1.innerText = selectedCountryCode;
          setPhoneregion(selectedCountryCode)
        }
        
        // Initialize the input field value with the selected country code
        updatePhoneNumberWithCountryCode();
        
        // Listen for the "countrychange" event
        phoneInputField.addEventListener("countrychange", updatePhoneNumberWithCountryCode);
        
        // Listen for the "blur" event on phone1 to update the selected country
        phoneInputField.addEventListener('input', function () {
          const searchTerm = this.value.toLowerCase();
          const countryList =Array.from( phoneInput.p);
          console.log(phoneInput)
          const filtercountrylist=countryList.filter(e=>e.name.toLowerCase().includes(searchTerm.toLowerCase()));
          const arrayIos = [];

          filtercountrylist.forEach(e => {
            arrayIos.push(e.iso2);
          });
          console.log(arrayIos);
          // Iterate through the country list and show/hide countries based on the search term
          if(searchTerm){
const countryList1 = document.querySelector('.iti__country-list');

// Check if the country list element exists before trying to remove the class
if (countryList1) {
  countryList1.classList.remove('iti__hide');

  // Get all elements with the class 'iti__country'
  const countryElements = document.querySelectorAll('.iti__country');

  // Iterate over each .iti__country element
  countryElements.forEach((e) => {
    const countryCode = e.getAttribute('data-country-code');
    
    if (arrayIos.includes(countryCode)) {
      e.classList.remove('iti__hide');
    } else {
      e.classList.add('iti__hide');
    }
    e.addEventListener('click',()=>{
      countryList1.classList.add('iti__hide');
      phoneInput.setCountry(countryCode);
      this.value=countryList.find(e=>e.iso2===countryCode).name;
    })
  });
}

          }
        
        });
        console.log(obgsheet);
      }
        },[option,isLoading])
        function toMoveRightcar(){
            if(I2>=1){
                document.querySelectorAll('.car').forEach(e=>e.style.transform=`translate(-${(I2-1)*50}%)`)
                setI2(I2-1);
            }
          }
          function toMoveLeftcar(){
            var carts=document.querySelectorAll('.car');
            if(I2<carts.length){
                console.log(I2)
                document.querySelectorAll('.car').forEach(e=>e.style.transform=`translate(-${(I2+1)*50}%)`)
                setI2(I2+1);
            }
          }
         
  return (
    <div>
      <Helmet>
        <link
          rel="canonical"
          href={`https://www.mdcrentalcars.com/en/${car.title}/${id}`}
        />
        <link rel="icon" href="/logo mdc Ca-02.svg" type="image/svg+xml" />
      </Helmet>

      <div className=" bg-[#F2F6FF] relative">
        <div
          className="absolute  w-full h-full bg-white z-[-400] backdrop-blur-[5px] opacity-[0.5]"
          ref={total}
        ></div>
        <div ref={form}>
          <div className="bg-[#F2F6FF]  lg:pt-10 md:pt-5 pt-3">
            <div className="flex flex-col lg:gap-4 md:gap-2 gap-1">
              <header className="flex lg:px-32 md:px-16 px-8 top-[4vh] w-full justify-between ">
                <i onClick={() => navigate("/en")} className="cursor-pointer">
                  <img
                    src={require("../img/Group.png")}
                    className="lg:w-[4rem] md:w-[2.5rem] w-[1.5rem]"
                    alt="icon"
                  />
                </i>
                <span className="relative">
                  <i
                    class="fa-solid fa-bars lg:text-[3rem] md:text-[2rem] text-[1rem] cursor-pointer  text-myblue"
                    onClick={() => {
                      document.querySelector(".fa-bars").style.display = "none";
                      document.querySelector(".fa-x").style.display = "block";
                      document.querySelector(".nav").style.display = "flex";
                    }}
                  ></i>
                  <i
                    class="fa-solid fa-x lg:text-[2.4rem] md:text-[1.7rem] text-[0.8rem] hidden cursor-pointer  text-myblue"
                    onClick={() => {
                      document.querySelector(".fa-bars").style.display =
                        "block";
                      document.querySelector(".fa-x").style.display = "none";
                      document.querySelector(".nav").style.display = "none";
                    }}
                  ></i>
                  <ul className="bg-bgsecond nav hidden text-myblue lists  flex-col rounded-md gap-2 absolute shadow-md lg:text-[1.4rem] md:text-[0.8rem] text-[0.6rem] lg:right-[2rem] right-[1.5rem] lg:w-[12vw] w-[30vw]">
                    <li className=" px-[5px] py-[1.5px] font-semibold border-b">
                      <a href="/en" className="w-full block">
                        home
                      </a>
                    </li>
                    <li className=" px-[5px] py-[1.5px]  font-semibold border-b cursor-pointer">
                      <a href="/en/aboutus" className="w-full block">
                        about us
                      </a>
                    </li>
                    <li className=" px-[5px] py-[1.5px]  font-semibold border-b cursor-pointer">
                      <a href="/en/privacypolicy" className="w-full block">
                        privacy policy
                      </a>
                    </li>
                  </ul>
                </span>
              </header>
              <div className="pt-[4%] lg:px-32 md:px-16 px-8 pb-[2%] flex flex-col  lg:text-[1.2rem] md:text-[0.9rem]  text-[0.5rem] gap-1">
                <span className="text-[#14B8A6] bg-[#CCFBF1] rounded-md lg:px-[10px] lg:py-[8px] md:px-[6px] md:py-[4px] px-[4px] py-[5px]">
                  <i className="fa-regular fa-clock lg:pr-[20px] md:pr-[14px] pr-[6px]"></i>
                  Book now for an incredible offer!
                </span>
                <span className="text-[#121EE4] bg-[#668FFF] rounded-md  lg:px-[10px] lg:py-[6px] md:px-[6px] md:py-[4px] px-[4px] py-[5px]">
                  <i className="fa fa-bolt lg:pr-[20px] md:pr-[14px] pr-[6px]"></i>
                  Quick confirmation-Get confirmation immediately after making a
                  reservation{" "}
                </span>
              </div>
              <h1 className="lg:text-[2rem] lg:mx-32 md:mx-16 mx-8 md:text-[1rem] text-[0.5rem] font-[600]">
                {" "}
                <span className="bg-white capitalize text-myblue lg:px-3 lg:py-2 px-2 py-1">
                  category
                </span>{" "}
                <span className="bg-myblue capitalize text-white lg:px-5 lg:py-2 px-3 py-1">
                  {car.category}
                </span>
              </h1>

              <div className="flex bg-white lg:mx-32 md:mx-16 mx-8 md:flex-row flex-col justify-between lg:px-[20px] md:px-[10px] px-[5px]  lg:gap-4 md:gap-2 gap-1  items-center ">
                <div className="lg:my-10 md:my-5  my-3 lg:p-4 md:p-2 p-1  w-fit flex flex-col car lg:gap-4 md:gap-2 gap-1  rounded-2xl">
                  <div className="flex lg:flex justify-center md:w-[42vw] w-[70vw] ">
                    {" "}
                    {car.id ? (
                      <img
                        className="md:w-[50%] w-[60%] lg:h-[200px] md:h-[150px] h-[100px]"
                        alt={car.title}
                        src={
                          car.front
                            ? car.image
                            : ` https://82.180.136.212/cars/images/${car.id}`
                        }
                      />
                    ) : (
                      <>...</>
                    )}
                  </div>
                  <div className="flex justify-between">
                    <span>
                      {car.title ? (
                        <h3 className="text-myblue lg:text-[2rem] md:text-[1.3rem] text-[1rem] font-[600]">
                          {car.title}
                        </h3>
                      ) : (
                        <>...</>
                      )}
                      <p className="font-[400] lg:tracking-[0.04rem] md:tracking-[0.02] tracking-[0.01rem] lg:text-[0.8rem] md:text-[0.7rem] text-[0.5rem] text-myblue">
                        Or similar
                      </p>
                    </span>
                    <span className="flex items-center lg:gap-2 gap-[2px]">
                      <span className="flex flex-col ">
                        <h4 className="text-[#4B91EC] self-end lg:text-[1.1rem] md:text-[0.8rem] text-[0.5rem] font-[500]">
                          Good
                        </h4>
                        <a
                          href="https://www.google.com/maps/place/My+drive+car/@31.6106031,-8.0527586,15z/data=!4m17!1m8!3m7!1s0xdafefb6d566b4c1:0x274d9b23e13e297f!2sMy+drive+car!8m2!3d31.5972256!4d-8.0517715!10e1!16s%2Fg%2F11pf1pd_rh!3m7!1s0xdafefb6d566b4c1:0x274d9b23e13e297f!8m2!3d31.5972256!4d-8.0517715!9m1!1b1!16s%2Fg%2F11pf1pd_rh?entry=ttu"
                          target="__blank"
                          className="text-[#d0cfcf] lg:text-[0.7rem] whitespace-nowrap md:text-[0.5rem] text-[0.3rem] font-[500]"
                        >
                          +20 reviews
                        </a>
                      </span>
                      <span className="lg:text-[1rem] md:text-[0.8rem] text-[0.6rem] text-center font-bold  text-white rounded-tl-md rounded-br-md rounded-tr-md  lg:p-2  p-1 bg-[#4B91EC]">
                        5.5
                      </span>
                    </span>
                  </div>
                  <div className="flex lg:gap-[16px]  w gap-[10px] justify-between text-[#121ee4b3] lg:text-[1.4rem] md:text-[1rem] text-[0.5rem]">
                    <span>
                      <span className="flex gap-1">
                        <i class="fa-solid fa-user lg:text-[1.5rem] md:text-[1.1rem] text-[0.9rem]"></i>
                        <p>{car.title === "Dacia lodgy" ? 7 : 5}</p>
                      </span>
                      <span className="flex gap-1 mt-2 ">
                        <i class="fa-solid fa-gas-pump lg:text-[1.5rem] md:text-[1.1rem] text-[0.9rem]"></i>
                        <p>Gasoline or Diesel</p>
                      </span>
                    </span>
                    <span>
                      <span className="flex justify-start gap-1">
                        <i
                          xmlns="http://www.w3.org/2000/svg"
                          className="fas fa-car-side lg:text-[1.5rem] md:text-[1.1rem] text-[0.9rem]"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        ></i>
                        <p>Automatic or Manual</p>
                      </span>
                      <span className="flex justify-start gap-1 lg:mt-2 mt-1 ">
                        <i className="fa-solid fa-wind"></i>
                        <p>Air conditioning</p>
                      </span>
                    </span>
                  </div>
                  <span className="lg:text-[1.2rem] md:text-[0.6rem] text-[0.4rem] px-3 py-[2px] max-w-fit border border-[#121ee4b3] text-[#121ee4b3]">
                    All-risk insurance
                  </span>
                </div>
                <div className="md:w-[42vw] w-[70vw]  pl-[3%]">
                  <ul className="bookul flex md:flex-col flex-row md:gap-0 gap-3 lg:mr-4 md:mr-2 mr-1 lg:mb-12 md:mb-6 mb-3">
                    <ul className="flex flex-col lg:gap-4 md:gap-2 gap-1">
                      <li>Free cancellation</li>
                      <li>Modifications</li>
                      <li>Airport supplement</li>
                    </ul>
                    <ul className="flex flex-col lg:gap-4 md:gap-2 gap-1  lg:mt-4 md:mt-2 ">
                      <li>Unlimited mileage</li>
                      <li>24/7 support</li>
                      <li>VAT/Tax</li>
                    </ul>
                  </ul>

                  <div className="lg:py-4 md:py-2 py-1 flex items-center justify-between">
                    <span className="prix lg:text-[2.6rem] md:text-[1.6rem] text-[1.2rem] font-[400] text-myblue">
                      {car.prix}
                      <span>Per day</span>
                    </span>
                    <a
                      href="https://wa.me/+212666703720"
                      target="__blank"
                      className="fab fa-whatsapp text-white lg:text-[2rem] md:text-[1.4rem]  text-[1.2rem] md:shadow-md shadow-sm md:p-[6px] p-[2px] md:rounded-md rounded-sm bg-green-500"
                    ></a>
                  </div>
                </div>
              </div>

              <Formik
                onSubmit={handleform12}
                initialValues={{
                  pick: "",
                  drop: "",
                  daystart: "",
                  hourstart: "",
                  dayend: "",
                  hourend: "",
                }}
                validationSchema={validationBooking}
              >
                {({ isValid }) => (
                  <Form
                    className="text-[#515151] lg:px-32 md:px-16 px-8 flex flex-col lg:text-[1.2rem] md:text-[1rem] text-[0.7rem] lg:mb-6 md:mb-3 mb-2  lg:mt-[5vh] md:mt-[3vh] mt-[2vh] w-[100%] lg:gap-[5vh] md:gap-[3vh] gap-[2vh]"
                    ref={booking}
                  >
                    <div className="w-full flex md:flex-row flex-col md:gap-[4%] gap-[10px] text-red-600">
                      <Field
                        as="select"
                        name="pick"
                        className="text-[#515151] bg-white md:w-[48%] w-[100%]  md:rounded-md rounded-sm md:h-[40px]  h-[30px]"
                      >
                        <option
                          className="lg:py-[10px] lg:px-[14px] py-[5px] px-[8px]"
                          value=""
                          disabled
                          selected
                        >
                          Pick up location
                        </option>
                        {airportsInMorocco.map((ap) => {
                          return (
                            <option
                              className="lg:py-[10px] lg:px-[14px] py-[5px] px-[8px]"
                              key={ap.name}
                              value={ap.name}
                            >
                              {ap.name + " / " + ap.city}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage name="pick" component="span" />
                      <Field
                        as="select"
                        name="drop"
                        className="bg-white text-[#515151]  md:w-[48%] w-[100%]  md:rounded-md rounded-sm md:h-[40px]  h-[30px]"
                      >
                        <option
                          value=""
                          className="lg:py-[10px] lg:px-[14px] py-[5px] px-[8px]"
                          disabled
                          selected
                        >
                          Drop off location
                        </option>
                        {airportsInMorocco.map((ap) => {
                          return (
                            <option
                              className="lg:py-[10px] lg:px-[14px] py-[5px] px-[8px]"
                              key={ap.name}
                              value={ap.name}
                            >
                              {ap.name + " / " + ap.city}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage name="drop" component="span" />
                    </div>
                    <div className="flex md:flex-row flex-col md:gap-[4%] gap-[10px] text-red-600 ">
                      <div className="flex md:gap-[4%] gap-[10px] md:w-[50%] w-[100%]">
                        <span className="bg-white text-[#515151] w-[56%] p-2  md:rounded-md rounded-sm md:h-[40px]  h-[30px] relative">
                          <Field
                            placeholder=""
                            className="w-full md:rounded-md rounded-sm lg:py-[10px] lg:px-[14px] py-[5px] px-[8px] bg-white absolute top-0 bottom-0 left-0 right-0"
                            max={maxDate.toISOString().split("T")[0]}
                            min={minDate.toISOString().split("T")[0]}
                            type="date"
                            name="daystart"
                            value={dayStart}
                            onChange={(e) => {
                              setDayStart(e.target.value);
                            }}
                          />
                          {!dayStart && (
                            <span className="placeholder">trip start</span>
                          )}
                        </span>{" "}
                        <ErrorMessage name="daystart" component="span" />
                        <Field
                          as="select"
                          name="hourstart"
                          className="bg-white w-[40%]   text-[#515151] md:h-[40px]  h-[30px] md:rounded-md rounded-sm"
                        >
                          <option
                            className="lg:py-[10px] lg:px-[14px] py-[5px] px-[8px]"
                            value=""
                            disabled
                            selected
                          >
                            hour start
                          </option>

                          {hoursInDay.map((hour) => {
                            return (
                              <option
                                className="lg:py-[10px] lg:px-[14px] py-[5px] px-[8px]"
                                key={hour}
                                value={hour}
                              >
                                {hour}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage name="hourstart" component="span" />
                      </div>
                      <div className="flex md:gap-[4%] gap-[10px] md:w-[50%] w-[100%] text-red-600 ">
                        <span className="bg-white text-[#515151] w-[56%] p-2  md:rounded-md rounded-sm md:h-[40px]  h-[30px] relative">
                          <Field
                            className="lg:py-[10px] lg:px-[14px] py-[5px] px-[8px] w-full bg-white absolute md:rounded-md rounded-sm  top-0 bottom-0 left-0 right-0 "
                            placeholder=""
                            max={maxDate.toISOString().split("T")[0]}
                            min={minDate.toISOString().split("T")[0]}
                            type="date"
                            name="dayend"
                            value={dayEnd}
                            onChange={(e) => {
                              setDayEnd(e.target.value);
                            }}
                          />
                          {!dayEnd && (
                            <span className="placeholder ">trip end</span>
                          )}
                        </span>

                        <ErrorMessage name="dayend" component="span" />
                        <Field
                          as="select"
                          name="hourend"
                          className="w-[40%]  bg-white text-[#515151]  md:h-[40px]  h-[30px] md:rounded-md rounded-sm"
                        >
                          <option
                            className="lg:py-[10px] lg:px-[14px] py-[5px] px-[8px]"
                            value=""
                            disabled
                            selected
                          >
                            hour end
                          </option>

                          {hoursInDay.map((hour) => {
                            return (
                              <option
                                className="lg:py-[10px] lg:px-[14px] py-[5px] px-[8px]"
                                key={hour}
                                value={hour}
                              >
                                {hour}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage name="hourend" component="span" />
                      </div>
                    </div>
                    <div className="bg-white lg:py-[10px] lg:px-[14px] py-[5px] px-[8px] rounded-lg text-myblue flex justify-between">
                      <p>Total rental price</p>
                      <p className="prix1">
                        {dayEnd && dayStart
                          ? calculateDaysBetweenDates(dayStart, dayEnd) *
                              car.prix <
                            0
                            ? 0
                            : calculateDaysBetweenDates(dayStart, dayEnd) *
                              car.prix
                          : 0}
                        <span className="lg:text-[1rem] md:text-[0.8rem] text-[0.5rem]">
                          €
                        </span>
                      </p>
                    </div>
                    <div className="flex justify-end">
                      <button
                        className={`text-white w-fit  ${
                          calculateDaysBetweenDates(dayStart, dayEnd) > 0 &&
                          isValid > 0
                            ? "bg-myblue pointer-events-auto"
                            : "bg-blue-300 pointer-events-none"
                        } py-[1px] lg:px-4 px-2 lg:text-[1.4rem] md:text-[0.8rem] text-[0.6rem] font-semibold lg:h-[2.6rem] md:h-[1.8rem] h-[1.5rem] md:rounded-md rounded-sm`}
                      >
                        continue
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <section>
              <div className="my-[2vh] lg:mx-32 md:mx-16 mx-8 text-myblue">
                <h2 className="lg:text-[1.6rem] md:text-[1rem] text-[0.6rem] font-semibold">
                  Upgrade to a higher vehicle class
                </h2>
                <h3 className="lg:text-[1.6rem] md:text-[1rem] text-[0.4rem] font-light">
                  Maybe you'd be interested in renting a higher-class car at an
                  affordable price
                </h3>
              </div>

              <div className="flex overflow-x-scroll lg:mx-32 md:mx-16 mx-8 cars gap-[2vw]">
                {cars.map((Car) => {
                  return (
                    <div className="relative lg:min-w-[27vw] lg:max-w-[27vw] md:max-w-[35vw] md:min-w-[35vw] max-w-[56vw] min-w-[56vw]  lg:my-10 md:my-5 my-2 lg:p-4 p-[10px] flex flex-col car lg:gap-4 md:gap-2 gap-1 bg-white lg:rounded-2xl md:rounded-lg rounded-[6px]">
                      <div className="flex justify-center">
                        {" "}
                        <img
                          className="w-[60%] lg:h-[130px] md:h-[100px] h-[80px]   "
                          alt={Car.title}
                          src={
                            Car.front
                              ? Car.image
                              : ` https://82.180.136.212/cars/images/${Car.id}`
                          }
                        />
                      </div>
                      <div className="flex justify-between">
                        <span>
                          <h3 className="text-myblue lg:text-[1.6rem] md:text-[1.4rem] text-[1.1rem] font-[600]">
                            {Car.title}
                          </h3>
                          <p className="font-semibold lg:tracking-[0.04rem] md:tracking-[0.02rem] tracking-[0.01rem] lg:text-[0.8rem] md:text-[0.6rem] text-[0.5rem] text-myblue">
                            Or similar
                          </p>
                        </span>
                        <span className="flex items-center lg:gap-2 gap-[2px]">
                          <span className="flex flex-col ">
                            <h4 className="text-[#4B91EC] self-end lg:text-[1.1rem] md:text-[0.8rem] text-[0.5rem] font-[500]">
                              Good
                            </h4>
                            <a
                              href="https://www.google.com/maps/place/My+drive+car/@31.6106031,-8.0527586,15z/data=!4m17!1m8!3m7!1s0xdafefb6d566b4c1:0x274d9b23e13e297f!2sMy+drive+car!8m2!3d31.5972256!4d-8.0517715!10e1!16s%2Fg%2F11pf1pd_rh!3m7!1s0xdafefb6d566b4c1:0x274d9b23e13e297f!8m2!3d31.5972256!4d-8.0517715!9m1!1b1!16s%2Fg%2F11pf1pd_rh?entry=ttu"
                              target="_blank"
                              className="text-[#d0cfcf] lg:text-[0.7rem] whitespace-nowrap md:text-[0.5rem] text-[0.3rem] font-[500]"
                              rel="nofollow noopener noreferrer"
                            >
                              +20 reviews
                            </a>
                          </span>
                          <span className="lg:text-[1rem] md:text-[0.8rem] text-[0.6rem] text-center font-bold  text-white rounded-tl-md rounded-br-md rounded-tr-md  lg:p-2  p-1 bg-[#4B91EC]">
                            5.5
                          </span>
                        </span>
                      </div>
                      <div className="flex justify-between md:my-[0] my-[10px] text-[#121ee4b3] font-semibold lg:text-[0.9rem] md:text-[0.7rem] text-[0.5rem]">
                        <span>
                          <span className="flex gap-2">
                            <i class="fa-solid fa-user lg:text-[1rem] md:text-[0.7rem] text-[0.6rem]"></i>
                            <p>{Car.title === "Dacia lodgy" ? 7 : 5}</p>
                          </span>
                          <span className="flex gap-1 mt-2">
                            <i class="fa-solid fa-gas-pump lg:text-[1rem] md:text-[0.7rem] text-[0.5rem]"></i>
                            <p>Gasoline or Diesel</p>
                          </span>
                        </span>
                        <span>
                          <span className="flex justify-start gap-1">
                            <i
                              xmlns="http://www.w3.org/2000/svg"
                              className="fas fa-car-side lg:text-[1rem] md:text-[0.7rem] text-[0.5rem]"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            ></i>
                            <p>Automatic or Manual</p>
                          </span>
                          <span className="flex justify-start gap-1 mt-2 ">
                            <i className="fa-solid fa-wind"></i>
                            <p>Air conditioning</p>
                          </span>
                        </span>
                      </div>
                      <span className="lg:text-[1.2rem] md:text-[0.6rem] text-[0.5rem] font-medium px-3 py-[2px] max-w-fit border border-[#121ee4b3] text-[#121ee4b3]">
                        All-risk insurance
                      </span>
                      <div className="lg:py-4 md:py-2 py-1 flex items-center justify-between">
                        <div className="prix lg:text-[2.6rem] md:text-[1.3rem] text-[1.1rem] font-semibold text-myblue">
                          {Car.prix}
                          <span className="lg:text-[1rem] md:text-[0.6rem] text-[0.4rem]">
                            Per day
                          </span>
                        </div>
                        <span className="flex justify-end items-center md:gap-[8px] gap-[4px]">
                          <a
                            className="text-white bg-myblue lg:py-2 md:py-[8px] py-[4px] lg:px-4 px-2 lg:text-[1.3rem] md:text-[1rem] text-[0.8rem] font-semibold  md:rounded-md  rounded-sm"
                            href={`/en/car/${Car.title}/${Car.id}`}
                          >
                            Booking
                          </a>
                          <a
                            href="https://wa.me/+212666703720"
                            target="__blank"
                            rel="nofollow noopener noreferrer"
                            className="fab fa-whatsapp text-white lg:text-[2rem] md:text-[1.4rem]  text-[1.2rem] md:shadow-md shadow-sm md:p-[6px] p-[4px] md:rounded-md rounded-sm bg-green-500"
                          ></a>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-center lg:mb-10 md:mb-5 mb-3 ">
                <i
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={toMoveLeftcar}
                  className="text-myblue lg:text-[3rem] md:text-[2.2rem] text-[1.5rem] cursor-pointer fa-solid fa-caret-left"
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="none"
                ></i>
                <i
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={toMoveRightcar}
                  className="text-myblue lg:text-[3rem] md:text-[2.2rem] text-[1.5rem] cursor-pointer fa-solid fa-caret-right"
                  width="43"
                  height="42"
                  viewBox="0 0 43 42"
                  fill="none"
                ></i>
              </div>
            </section>
            <Footer px={32} />
          </div>
        </div>
        <section
          ref={form1}
          className="formscroll max-h-[100vh] shadow-md shadow-black fixed bg-[#F2F6FF] md:overflow-y-scroll translate-x-[-50%] translate-y-[-100%] md:w-[44vw] w-[100vw] top-[220%] left-[50%] "
        >
          <div className="pt-10 text-myblue lg:px-20 md:px-10 px-5 flex flex-col lg:gap-8 md:gap-6 gap-4">
            <div className="flex text-myblue justify-between">
              <i
                class="fa-solid bg-white flex justify-center items-center p-2   md:rounded-md rounded-sm fa-arrow-left cursor-pointer"
                onClick={handleform22}
              ></i>
              <span
                className="bg-white px-3 py-2 cursor-pointer font-[600] md:rounded-md rounded-sm justify-center flex items-center"
                onClick={handleform22}
              >
                x
              </span>
            </div>
            <div className="flex lg:gap-10 md:gap-5 gap-3 items-center bg-white w-fit lg:p-4 md:p-2 p-1">
              <span className="flex flex-col md:gap-[4px] gap-[2px]">
                <h2 className="lg:text-[1.85rem] md:text-[0.9rem] text-[0.45rem] font-[400]">
                  Driver details
                </h2>
                <h4 className="lg:text-[1.25rem] md:text-[0.65rem] text-[0.4rem] font-[300]">
                  Secure transaction
                </h4>
              </span>
              <i
                className="fa-solid fa-id-card lg:text-[3rem] md:text-[2rem] text-[1.4rem]"
                width="26"
                height="35"
                viewBox="0 0 26 35"
                fill="none"
              ></i>
            </div>
            <Formik
              validationSchema={validationContinues}
              initialValues={{ nameclient: "", email: "", pn: "", fn: "" }}
              onSubmit={handleform13}
              ref={countinues}
            >
              {({ isValid }) => (
                <Form className="flex flex-col lg:text-[1rem] md:text-[0.8rem] text-[0.6rem] lg:gap-6 md:gap-3 gap-1">
                  <div className="flex flex-col lg:gap-6 md:gap-3 gap-[10px] lg:pb-6 md:pb-3 pb-1 lg:border-b-2 md:border-b-1">
                    <div className="flex md:flex-row flex-col md:gap-[2%] gap-[10px]">
                      <Field
                        type="text"
                        className="p-2 placeholder:text-[#D0CFCF] md:rounded-md rounded-sm md:w-[49%] w-full"
                        name="nameclient"
                        placeholder="Full Name"
                      ></Field>
                      <ErrorMessage name="nameclient" component={"span"} />
                      <Field
                        type="email"
                        className="p-2 placeholder:text-[#D0CFCF] md:rounded-md rounded-sm md:w-[49%] w-full"
                        name="email"
                        placeholder="Email"
                      ></Field>
                      <ErrorMessage name="email" component={"span"} />
                    </div>
                    <div className="flex md:flex-row flex-col items-end md:gap-[2%] gap-[10px]">
                      <div className="flex md:flex-col flex-row gap-[4px] md:w-[49%] w-full ">
                        <input
                          type="text"
                          placeholder="chose your country"
                          id="phone"
                          className="p-2 md:w-[100%] w-[5vw] "
                        />
                        <div className="flex  w-full h-full">
                          <p
                            id="phone1"
                            className=" md:min-w-[3vw] min-w-[10vw] bg-[#D0CFCF] rounded-md justify-center flex items-center"
                          ></p>
                          <Field
                            type="text"
                            className="placeholder:text-[#D0CFCF] p-2 w-full  md:rounded-md rounded-sm "
                            name="pn"
                            placeholder="Phone Number"
                          ></Field>
                        </div>
                      </div>
                      <ErrorMessage name="pn" component={"span"} />

                      <Field
                        type="text"
                        className="p-2    placeholder:text-[#D0CFCF] md:rounded-md rounded-sm md:w-[49%] w-full"
                        name="fn"
                        placeholder="Flight number"
                      ></Field>
                      <ErrorMessage name="fn" component={"span"} />
                    </div>
                  </div>
                  {/*<div className='flex flex-col gap-1 lg:my-[10px] md:my-[5px] my-[3px]'>
        <h3 className='lg:text-[1.6rem] md:text-[0.7rem] text-[0.6rem] font-[400]'>Choose the payment method</h3>
        <div className='flex gap-1  lg:text-[0.96rem] md:text-[0.7rem] text-[0.5rem]'>
          <Field type='radio' id='in-cash' name='pay' value={1} onChange={(e)=>setRadio(e.target.value)} checked></Field><label for='in-cash'>In cash</label></div>
    </div>*/}
                  <ReCAPTCHA
                    sitekey="6Le1_zgpAAAAADFJQ4knDmKO7t7Rmaik0tgcvXjM"
                    onChange={handleCaptchaChange}
                  />
                  <div className="flex justify-end lg:mb-20 md:mb-10 mb-5">
                    <button
                      className={`text-white w-fit  ${
                        captcha && isValid && phoneregion
                          ? "bg-myblue pointer-events-auto"
                          : "bg-blue-300 pointer-events-none"
                      }  lg:px-10 md:px-5 px-2 lg:text-[1.3rem]  text-[0.8rem] font-semibold  py-[6px] md:rounded-md rounded-sm`}
                    >
                      continue
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer px={20} />
        </section>
        <section
          ref={form2}
          className="fixed formscroll max-h-[100vh] shadow-2xl shadow-black bg-[#F2F6FF] md:overflow-y-scroll md:w-[44vw] w-[100vw] top-[220%] left-[50%] translate-x-[-50%] translate-y-[-100%]  "
        >
          <div className="lg:pt-10 md:pt-10 pt-5 text-myblue lg:px-20 md:px-10 px-5 flex flex-col lg:gap-8 md:gap-4 gap-2">
            <div className="flex text-myblue justify-between">
              <i
                class="fa-solid bg-white flex justify-center items-center p-2 rounded-md fa-arrow-left cursor-pointer"
                onClick={handleform123}
              ></i>
              <span
                className="bg-white px-3 py-2 cursor-pointer font-[600] rounded-md justify-center flex items-center"
                onClick={handleform23}
              >
                x
              </span>
            </div>
            <div className="flex gap-10 items-center bg-white w-fit lg:p-4 p-2">
              <span className="flex flex-col md:gap-[4px] gap-[2px]">
                <h2 className="lg:text-[1.85rem] md:text-[0.9rem] text-[0.5rem] font-[400]">
                  Payment
                </h2>
                <h4 className="lg:text-[1.25rem] md:text-[0.6rem] text-[0.4rem] font-[300]">
                  In cash
                </h4>
              </span>
              <i
                className="fa-solid fa-money-bill-wave lg:text-[3rem] md:text-[2rem] text-[1.4rem]"
                width="26"
                height="35"
                viewBox="0 0 26 35"
                fill="none"
              ></i>
            </div>
            <div className="flex flex-col justify-center items-center md:gap-2 gap-1 mt-10 mb-32">
              <div className="flex  justify-center items-center md:gap-2 gap-1">
                <a
                  className="text-white w-fit  bg-myblue py-[6px] px-[6px] lg:text-[1.4rem] md:text-[1rem] text-[0.8rem]  font-semibold  rounded-md"
                  rel="nofollow noopener noreferrer"
                  href={`https://wa.me/+212666703720?text=I have reserved a ${obgsheet.name}`}
                  target="_blank"
                  onClick={handleSubmit}
                >
                  send your request
                </a>
                <a
                  className="text-white w-fit  bg-myblue py-[6px]  px-[6px] lg:text-[1.4rem] md:text-[1rem] text-[0.8rem]  font-semibold  rounded-md "
                  rel="nofollow noopener noreferrer"
                  href={`https://wa.me/+212666703720?text=I have reserved a ${obgsheet.name}`}
                  target="_blank"
                  onClick={handleSubmit}
                >
                  <svg
                    className="md:w-8 md:h-8 w-4 h-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.0079 1.01229C22.4244 0.413344 21.5607 0.190206 20.7554 0.425088L1.97601 5.88609C1.12632 6.12215 0.524078 6.79978 0.361845 7.66062C0.196111 8.53673 0.775015 9.6489 1.53132 10.114L7.40323 13.7229C8.00547 14.0929 8.78279 14.0001 9.28116 13.4974L16.0051 6.73167C16.3436 6.37934 16.9038 6.37934 17.2423 6.73167C17.5807 7.07224 17.5807 7.62422 17.2423 7.97654L10.5067 14.7435C10.0071 15.245 9.91376 16.0259 10.2814 16.6319L13.8692 22.5627C14.2894 23.2673 15.013 23.6666 15.8067 23.6666C15.9 23.6666 16.0051 23.6666 16.0985 23.6549C17.0088 23.5375 17.7325 22.915 18.0009 22.0342L23.5682 3.2789C23.8133 2.4803 23.5915 1.61124 23.0079 1.01229Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <p className="text-[#000033] opacity-[0.5] font-semibold lg:text-[0.8rem] md:text-[0.6rem] text-[0.3rem]">
                "The order will be confirmed immediately upon submission."
              </p>
            </div>
          </div>
          <Footer px={20} />
        </section>
      </div>
    </div>
  );
}

export default Car
